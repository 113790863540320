$primary: #D90429;
$lighter-red: #EF233C;
$secondary: #2B2D42;

$body-bg: #EDF2F4;
$body-color: $secondary;

$form-range-thumb-width: 1.5rem;
$form-range-thumb-height: 1.5rem;

$form-range-track-height: .5rem;
$form-range-thumb-active-bg: tint-color($primary, 70%);
$form-range-thumb-bg: $primary;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;900&family=Red+Hat+Mono:wght@400&display=swap');
@import url('icons/icons.css');

$font-family-base:  'Inter', sans-serif;

$input-btn-focus-color: rgba($secondary, .25) !default;

$input-bg: white;
$link-color: $secondary;

$navbar-light-brand-color: $secondary;
$nav-link-font-weight: 500;


::-moz-selection { 
  color: white;
  background: $lighter-red;
}

::selection {
  color: white;
  background: $lighter-red;
}

.form-check.form-switch{
  margin-top: .5rem;
  margin-right: 1.5rem !important;
  
  .form-check-input {
  width: 3.3em !important;
  height: 1.6rem !important;
  margin-right: .5rem;
  margin-top: 0;
  }
  .form-check-label{
    font-weight: 500;
  }
}